import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import React, {useEffect, useState} from "react";
import { BASE_URL } from "../../assets/js/_variables";

// Componentes
import Layout from "../../components/Layout";
import Slider from "../../components/Slider";

const Whales = () => {
  const [lang, setLang] = useState()
  
  const txt = {
    en: {
      pageTitle: 'Whales Watching | HB Privileged',
      title: 'WHALES WATCHING',
      subtitle: 'TOUR OVERVIEW',
      text: "Board a Beach Boy Cruise ship in Puerto Vallarta bound for one of the adventures that can only occur in the waters of the Mexican Pacific. Year after year, these huge cetaceans come to the calm waters of our destination to mate and have their young. Thus, between the months of December and March, Beach Boy Cruises allocates one of its boats to go and observe them in the best safety and friendliness conditions for both tourists and our marine mammals. Get as close as the maritime regulations allow and take the most spectacular photographs while our marine biologist gives us all the information about the life of the types of whales that we can find. It is definitely a once-in-a-lifetime experience that will take your breath away. Enjoy unlimited breakfast, snacks, and drinks on board on this 4-hour whale watching tour. The sighting is guaranteed! It's fun for the whole family!",
      frequency: 'Frequency:',
      hours: 'Hours:',
      duration: 'Duration:',
      btn: 'Book now',
    },
    es: {
      pageTitle: 'Avistamiento de Ballenas | HB Privileged',
      title: 'AVISTAMIENTO DE BALLENAS',
      subtitle: 'RESUMEN DEL TOUR',
      text: 'Aborda una embarcación de Cruceros Beach Boy en Puerto Vallarta con destino a una de las aventuras que sólo pueden darse en las aguas del pacífico mexicano. Año con año, estos cetáceos descomunales vienen a las aguas tranquilas de nuestro destino para aparearse y tener a sus críos. Así, entre los meses de diciembre y marzo, Cruceros Beach Boy destina una de sus embarcaciones para ir a observarlas en las mejores condiciones de seguridad y amabilidad tanto para turistas como para nuestros mamíferos marinos. Acércate tanto como las regulaciones marítimas lo permitan y toma las más espectaculares fotografías mientras nuestra bióloga marina nos da toda la información de la vida de los tipos de ballenas que podemos encontrar. Definitivamente es una experiencoia única en la vida que te dejará sin aliento. Disftuta de un desayuno, botanas y bebidas ilimitadas a bordo, en este tour de 4 horas a ver las ballenas. El avistamiento está garantizado! Es diversión para toda la familia!',
      frequency: 'Frecuencia:',
      hours: 'Horas:',
      duration: 'Duración:',
      btn: 'Reserva este tour',
    }
  }

  const images = [
    `${BASE_URL}/whales/banner.jpg`,
    `${BASE_URL}/whales/banner.jpg`,
  ]

  const getLang = () => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('lang')
    }
  }

  useEffect(() => {
    if(getLang() === 'es') {
      setLang('es')
    } else {
      setLang('en')
    }
  }, [])

  return (
    <Layout pageTitle={ lang === 'es' ? txt.es.pageTitle : txt.en.pageTitle }>
      <div>
        {/*</div><div className="h-80 overflow-hidden">*/}
        <div className="h-70 overflow-hidden">
          <img src={`${BASE_URL}/banners/ballenas.jpg`} className="w-full object-cover" alt="" />
        </div>
        <div className="section">
          <h2 className="text-2xl md:text-4xl font-bold mb-2">
            { lang === 'es' ? txt.es.title : txt.en.title }
          </h2>
          <div className="mb-4">
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <h3 className="text-xl md:text-2xl font-semibold mb-4">
                { lang === 'es' ? txt.es.subtitle : txt.en.subtitle }
              </h3>
              <p className="normal-text text-justify mb-4">
                { lang === 'es' ? txt.es.text : txt.en.text }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.frequency : txt.en.frequency }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.hours : txt.en.hours }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.duration : txt.en.duration }
              </p>
            </div>

            <div className="">
              <div className="rounded overflow-hidden">
                <Slider images={images} />
              </div>
              
              <div className="text-center py-8">
                <Link
                  className="text-center w-1/2 mt-16 text-xl font-bold bg-sky-800 text-neutral-50 py-2 px-4 rounded-full" 
                  to={`${BASE_URL}/contacto`}
                >
                  { lang === 'es' ? txt.es.btn : txt.en.btn }
                </Link>
              </div>
            </div>
          </div>
        </div>

      </div>
    </Layout>
  );
}
 
export default Whales;